import React from 'react';


const BackToTop = () => {
    return (
        <a
            href="https://api.whatsapp.com/send/?phone=918121697022&text&type=phone_number&app_absent=0"
            className="back-to-top"
            target="_blank"
            rel="noopener noreferrer"
        >
            <i className="bi bi-whatsapp"></i> Chat with Us
        </a>
    );
};

export default BackToTop;
