
"use client";
import React, { useEffect, useState } from "react";
import axios from "axios";
import LightGallery from 'lightgallery/react';

// Import CSS
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// Import plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import '../styles/GalleryPage.css';

const GalleryPage = () => {
    const [galleryData, setGalleryData] = useState({});
    const [galleryItems, setGalleryItems] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGalleryData = async () => {
            try {
                const response = await axios.post("https://api.blingnbliss.in/api/get-gallery");
                console.log("response is gallery", response.data.data);

                if (response.data.success) {
                    setGalleryData(response.data.data);
                    setSelectedLocation(Object.keys(response.data.data)[0]);
                }

                setLoading(false);
            } catch (error) {
                setError("Failed to fetch data.");
                setLoading(false);
            }
        };

        fetchGalleryData();
    }, []);

    useEffect(() => {
        if (selectedLocation && galleryData[selectedLocation]) {
            setGalleryItems(galleryData[selectedLocation]); // Update gallery items based on selected location
        }
    }, [selectedLocation, galleryData]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="page-content">
            <div className="gallery-area" style={{ background: '#CBBBDD', paddingTop: '220px' }}>
                <div className="container">
                    {/* Dropdown to select location */}

                    <div className="row mb-4 justify-content-center gallry-row">
                        <div className="col-lg-4 col-sm-6 m-2 ">
                            <div className="form-group ">
                                <label htmlFor="location" className="slot-text">Select Location</label>
                                <select
                                    id="location"
                                    className="form-control gallery-dropdown"
                                    value={selectedLocation}
                                    onChange={(e) => setSelectedLocation(e.target.value)}
                                    required
                                >
                                    {Object.keys(galleryData).map((location, index) => (
                                        <option key={index} value={location}>
                                            {location}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* Gallery Content */}
                  
                    <div className="gallery-data">
                        {galleryItems.map((item, index) => (
                            <div key={index} className="gallery-item-container">
                                <div className="section-title gallery-collection">
                                    <h2 className="theater-name">{item.theater_name}</h2>
                                </div>
                                <LightGallery plugins={[lgThumbnail, lgZoom]} mode="lg-fade">
                                    {item.gallery_details.image2 && JSON.parse(item.gallery_details.image2).map((image, idx) => (
                                        <a
                                            key={idx}
                                            href={`https://api.blingnbliss.in/${image}`}
                                            className="gallery-item"
                                        >
                                            <img
                                                className="gallery-images m-2"
                                                alt={`Gallery Image ${idx + 1}`}
                                                src={`https://api.blingnbliss.in/${image}`}
                                            />
                                        </a>
                                    ))}
                                </LightGallery>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div >
    );
};

export default GalleryPage;

