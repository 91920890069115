// src/pages/AboutUs.js
"use client";
import React from 'react';
import { Link } from 'react-router-dom';
const AboutUs = () => {
    return (
        <div className='page-content'>
            {/* About Area */}
            <div className="about-area-two ptb-120 bg-image" style={{ background: '#CBBBDD', paddingTop: '280px' }}  >
                <div className="container">
                    <div className="row h-100 align-items-center">
                        <div className="col-xl-6 col-lg-6 col-sm-12 about-page">
                            <div className='contact-box about-contact'>
                                <div className='section-title about-title'>
                                    <h2 >About Bling n' Bliss</h2>
                                    {/* <div className="bar"></div> */}
                                    <p className='about-text'>
                                        Welcome to Bling n' Bliss, where we make private theater and event celebrations truly special. Enjoy our exclusive spaces where your favorite movies and special moments shine. Whether it’s a big anniversary, a cozy birthday, a romantic date, or any other special event, we’ll make sure it’s unforgettable.
                                    </p>
                                    <p className='about-text'>
                                        At Bling n' Bliss, we offer more than just a movie screening; we create a complete experience. Book our beautiful venues for an incredible movie night with top-quality sound and stunning 4K visuals. Whether you choose a new hit or an old favorite, every movie will look and sound amazing on our big screens.
                                    </p>
                                </div>


                                <Link className="btn1 btn-primary about-btn" to="/contact-us">Contact Us</Link>
                            </div>


                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-12 about-us-image">
                            <div className="aboutus-contact-box about-images">
                                <img
                                    alt="about"
                                    loading="lazy"
                                    width="750"
                                    height="900"
                                    className="about-img1 home-image"
                                    src="/images/about.jpeg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
 