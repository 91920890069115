import React from "react";
import { useNavigate } from 'react-router-dom';
const Payment = ()=>
   
{
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/'); // Navigate to the home page
      };
    return(
        <div className="page-content">
            <div className="about-area-two ptb-120 bg-image "style={{background:'#CBBBDD',paddingTop: '240px'}}>
                <div className="container">
                    <div className="row h-100 align-items-center thank-you">
                       
                        <div className="col-lg-6 col-sm-6 justify-content-center p-2">
                            <div className="section-title">
                                <h3 className="thank-heading">Thank You for Booking with Bling n Bliss!</h3>
                                {/* <div className="bar"></div> */}
                              <p>We’re thrilled to be a part of your special moments! Your booking is confirmed, and we can’t wait to provide you with an unforgettable experience.</p>
                              <div className="section-title what-next">
                              <h5>What’s Next?</h5>
                              {/* <div className="bar"></div> */}
                              <ul className="thank-list">
                                <li>
                                Check Your Email:
                                A confirmation email has been sent to you with all the details of your booking. Please review it carefully.
                                </li>
                                <li>
                                Get Ready:
                                Arrive at least 15 minutes before your scheduled time to make the most of your experience.
                                </li>
                                <li>Contact Us:
                                If you have any questions or need to make adjustments, feel free to reach out to our support team. We're here to ensure everything is perfect for you.</li>
                            </ul>
                            </div>
                            <div className="section-title why-bliss">
                            <h5>Why Bling n Bliss?</h5>
                            {/* <div className="bar"></div> */}
                            <p>At Bling n Bliss, we’re dedicated to creating luxurious, personalized experiences for all our guests. Whether it's a romantic date, a family outing, or a celebration with friends, we’re here to make it memorable.</p>
                            <p>We look forward to hosting you soon!</p>
                            </div>
                          </div>
                          <button type="button" className="btn btn-primary mt-3 thank-btn"  onClick={handleBackClick}>
                    Back
                  </button>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="thank-you-img">
                                <img
                                    alt="about"
                                    loading="lazy"
                                    width="750"
                                    height="650"
                                    className="about-img1 home-image"
                                    src="/images/thankyou1.png"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payment;