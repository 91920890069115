"use client";
import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { Carousel } from 'react-bootstrap';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import LightGallery from 'lightgallery/react';
// Import CSS
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// Import plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { Next } from "react-bootstrap/esm/PageItem";
const SelectedTheater = ({ theaterId }) => {
  const { theater_id } = useParams();
  const location = useLocation();
  const state = location.state || {};
  const userdate = state.selectedDate1 || '';
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState(userdate);
  const [couponDetails, setCouponDetails] = useState({});
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [numberOfPersons, setNumberOfPersons] = useState("");
  const [selectedSlotId, setSelectedSlotId] = useState("");
  const [theaterData, setTheaterData] = useState({});
  const [price, setPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [currentStep, setCurrentStep] = useState("none");
  const [initialPrice, setInitialPrice] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(true);
  const [selectedExtradecorations, setselectedExtradecorations] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [addons, setAddons] = useState([]);
  const [cakes, setCakes] = useState([]);
  const [roses, setRoses] = useState([]);
  const [selectedRoses, setSelectedRoses] = useState([]);
  const [isEggless, setIsEggless] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState("0.50");
  const [selectedCake, setSelectedCake] = useState(null)
  const [selectedDecoration, setSelectedDecoration] = useState(null);
  const [photograhpy, setPhotograhpy] = useState([]);
  const [selectedPackagePrice, setSelectedPackagePrice] = useState(0);
  const navigate = useNavigate();
  const [extradecorations, setExtradecorations] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(0);
  const descriptionPoints = theaterData.description ? theaterData.description.split('\r\n') : [];
  const responseData = [];
  const baseAmount = 750;
  const cakePrice = Number(selectedCake?.price) || 0;
  const [galleryItems, setGalleryItems] = useState([]);
  const [ledLetter, setLedLetter] = useState('');
  const [ledNumber, setLedNumber] = useState('');
  const [showNameInput, setShowNameInput] = useState(false);

  // Calculate payable_amount
  const payable_amount = baseAmount + cakePrice;
  const [formData, setFormData] = useState({
    username: '',
    whatsappNumber: '',
    mail: '',
    your_nickname: '',
    partner_nickname: '',
    paymentType: 'paypalAmount',
    totalAmount: '',
    ledletters: '',
    lednumber: '',
    personName: ''

  });
  const [errors, setErrors] = useState({});
  const today = new Date().toISOString().split('T')[0];

  const ProceedvalidateForm = () => {
    const newErrors = {
      username: '',
      whatsappNumber: '',
      mail: ''
    };

    let isValid = true;

    if (!formData.username) {
      newErrors.username = 'Name is required';
      isValid = false;
    }

    if (!formData.whatsappNumber) {
      newErrors.whatsappNumber = 'WhatsApp Number is required';
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.whatsappNumber)) {
      newErrors.whatsappNumber = 'WhatsApp Number must be 10 digits';
      isValid = false;
    }

    if (!formData.mail) {
      newErrors.mail = 'Email is required';
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.mail)) {
      newErrors.mail = 'Email is invalid';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  useEffect(() => {
    // Ensure Cashfree script is loaded
    if (!window.Cashfree) {
      console.error('Cashfree SDK is not loaded');
    }
  }, []);
  const handleEgglessToggle = () => {
    setIsEggless(!isEggless);  // Toggle the eggless state
  };
  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    // console.log("isChecked", checked);
  };


  const validateForm = () => {
    if (!selectedDate || !selectedSlot || !numberOfPersons) {
      toast.error("All fields are required");
      return false;
    }
    return true;
  };
  const handleCloseModal = () => {
    document.body.classList.remove('modal-open');
    setCurrentStep("none")
  };


  const ApplyCouponCard = async () => {
    if (isCouponApplied) {
      // Remove coupon
      setResponseMessage('');
      setPrice(originalPrice);
      setIsCouponApplied(false);
      setCoupon('');
      return;
    }

    try {
      const couponResponse = await axios.post("https://api.blingnbliss.in/api/get-coupon", {
        code: coupon,
        email: formData.mail,
        whatsapp_number: formData.whatsappNumber
      });

      // console.log("couponResponse", couponResponse.data);

      // Check the status of the coupon
      if (couponResponse.data.status) {
        // Success: Apply coupon
        setCouponDetails(couponResponse.data.coupon);
        // console.log("coupon details ", couponResponse.data.coupon.code);
        // console.log("coupon details ", couponResponse.data.coupon.coupon_value);

        setResponseMessage(couponResponse.data.message);
        setIsSuccess(true);

        // Determine if coupon is a percentage or fixed value
        const couponType = couponResponse.data.coupon.coupon_type;
        const couponValue = parseFloat(couponResponse.data.coupon.coupon_value);

        let newPrice = price;

        if (couponType === 'percentage') {
          // Calculate percentage discount
          const discountAmount = (couponValue / 100) * price;
          newPrice = price - discountAmount;
        } else if (couponType === 'fixed') {
          // Subtract fixed value discount
          newPrice = price - couponValue;
        }

        setPrice(newPrice);
        // console.log("applied coupon price", newPrice);
        setIsCouponApplied(true);
        setOriginalPrice(price);
        // console.log("original price", price);
      } else {
        setResponseMessage(couponResponse.data.message);
        setIsSuccess(false);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setResponseMessage(error.response.data.message);
      } else {
        setResponseMessage("Failed to apply coupon. Please try again.");
      }
    }
  };


  const handleCouponChange = (e) => {
    // console.log(".......................");

    const newCoupon = e.target.value;
    setCoupon(newCoupon);

    if (newCoupon.trim() === '') {
      setResponseMessage('');
      // console.log("orinal price to show ", price);

      setOriginalPrice(price);
      setIsCouponApplied(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    axios.post(`https://api.blingnbliss.in/api/get-theatres-By-Id/${theater_id}/${selectedDate}`)
      .then(response => {
        if (response.data.success) {

          setTheaterData(response.data.data);
          // console.log("response ", setTheaterData);

          setPrice(response.data.data.price);
          setInitialPrice(response.data.data.price);

          if (response.data.data.extraPackage && response.data.data.extraPackage.length > 0) {
            setSelectedPackageIndex(response.data.data.extraPackage[0].id); // Default to the first package
          } else {
            setSelectedPackageIndex(null); // No package selected if none are available
          }

        } else {
          toast.error("Failed to load theater data");
        }
      })
      .catch(error => {
        toast.error("An error occurred while fetching theater data");
      }).finally(() => {
        setLoading(false);
      });;
  }, [theaterId, selectedDate]);


  useEffect(() => {
    if (theaterData.extraPackage && theaterData.extraPackage.length > 0) {
      const defaultPackage = theaterData.extraPackage[0];
      setSelectedPackageIndex(defaultPackage.id);
      setSelectedPackagePrice(parseFloat(defaultPackage.price) || 0);
    }
  }, [theaterData]);

  // Update selected slot ID whenever selectedSlot changes
  useEffect(() => {
    if (theaterData.slots && selectedSlot) {
      const selectedSlotData = theaterData.slots.find(slot => `${slot.start_time} to ${slot.end_time}` === selectedSlot);
      if (selectedSlotData) {
        setSelectedSlotId(selectedSlotData.slot_id);
      }
    }
  }, [selectedSlot, theaterData.slots]);

  const handlePackageSelect = (packageId) => {
    if (selectedPackageIndex !== packageId) {
      setSelectedPackageIndex(packageId);

      const selectedPackage = theaterData.extraPackage.find(pkg => pkg.id === packageId);
      if (selectedPackage) {
        const price = parseFloat(selectedPackage.price) || 0;
        setSelectedPackagePrice(price);
      }
      // console.log("Package Selected:", packageId);
    }
  };
  const formatDateToIndian = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleRadioChange = (e) => {
    const { value } = e.target;
    // Update the state with the new payment type
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        paymentType: value
      };
    });

  };


  const calculateDuration = (start, end) => {
    const convertTo24HourFormat = (timeStr) => {
      const [time, modifier] = timeStr.split(' ');
      let [hours, minutes] = time.split(':').map(num => parseInt(num, 10));

      if (modifier === 'PM' && hours !== 12) {
        hours += 12;
      }

      if (modifier === 'AM' && hours === 12) {
        hours = 0;
      }

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    const start24HourFormat = convertTo24HourFormat(start);
    const end24HourFormat = convertTo24HourFormat(end);

    const startTime = new Date(`1970-01-01T${start24HourFormat}:00`);
    const endTime = new Date(`1970-01-01T${end24HourFormat}:00`);

    return (endTime - startTime) / (1000 * 60 * 60); // Duration in hours
  };

  useEffect(() => {
    // const calculateDuration = (start, end) => {
    //   const startTime = new Date(`1970-01-01T${start}:00`);
    //   const endTime = new Date(`1970-01-01T${end}:00`);
    //   return (endTime - startTime) / (1000 * 60 * 60);
    // };

    const handleSlotConfirmation = () => {
      if (theaterData && selectedSlot) {
        const selectedSlotData = theaterData.slots.find(
          slot => `${slot.start_time} to ${slot.end_time}` === selectedSlot
        );
    
        if (selectedSlotData) {
          const { start_time, end_time } = selectedSlotData;
          // console.log("selectedSlotData.start_time", start_time);
          // console.log("selectedSlotData.end_time", end_time);
          
          const duration = calculateDuration(start_time, end_time);
          // console.log("duration line", duration);
          
          if (duration === 1.5) {
            price = theaterData.price_halfandhour || theaterData.price; 
        // console.log("price_halfandhour line 355",price);
        
            // Update the price using the appropriate function
            setPrice(price);
           
          }
        }
      }
      return true;
    };
    

    let price = 0;

    // If an extra package is selected, start with the extra package price
    if (theaterData.extraPackage && selectedPackageIndex) {
      const selectedExtraPackage = theaterData.extraPackage.find(pkg => pkg.id === selectedPackageIndex);
      if (selectedExtraPackage) {
        price = parseFloat(selectedExtraPackage.price) || 0;
      }

      // Add decorations and other extras to the extra package price
      if (selectedDecoration && selectedDecoration.price) {
        price += parseFloat(selectedDecoration.price) || 0;
      }

      if (selectedCake && selectedCake.price) {
        price += parseFloat(selectedCake.price) || 0;
      }

      if (selectedRoses && selectedRoses.length > 0) {
        selectedRoses.forEach(rose => {
          if (rose.price) {
            price += parseFloat(rose.price) || 0;
          }
        });
      }

      if (selectedExtradecorations && selectedExtradecorations.length > 0) {
        selectedExtradecorations.forEach(extradecoration => {
          if (extradecoration.price) {
            price += parseFloat(extradecoration.price) || 0;
          }
        });
      }

      if (selectedPhotos && selectedPhotos.length > 0) {
        selectedPhotos.forEach(photo => {
          if (photo.price) {
            price += parseFloat(photo.price) || 0;
          }
        });
      }

    } else {
      // If no extra package is selected, calculate the price based on the base theater price, slot, and extras
      price = initialPrice || 0;

      if (handleSlotConfirmation()) {
        if (theaterData && numberOfPersons) {
          const selectedPerson = parseInt(numberOfPersons, 10);
          const extraCharge = theaterData.extraChargePersons.find(p => p.person === selectedPerson);
          if (extraCharge) {
            price += extraCharge.charge || 0;
          }
        }

        if (theaterData && selectedSlot) {
          const selectedSlotData = theaterData.slots.find(slot => `${slot.start_time} to ${slot.end_time}` === selectedSlot);
          if (selectedSlotData) {
            setSelectedSlotId(selectedSlotData.slot_id);
          }
        }

        // Add decorations and other extras to the base theater price
        if (selectedDecoration && selectedDecoration.price) {
          price += parseFloat(selectedDecoration.price) || 0;
        }

        if (selectedCake && selectedCake.price) {
          price += parseFloat(selectedCake.price) || 0;
        }

        if (selectedRoses && selectedRoses.length > 0) {
          selectedRoses.forEach(rose => {
            if (rose.price) {
              price += parseFloat(rose.price) || 0;
            }
          });
        }

        if (selectedExtradecorations && selectedExtradecorations.length > 0) {
          selectedExtradecorations.forEach(extradecoration => {
            if (extradecoration.price) {
              price += parseFloat(extradecoration.price) || 0;
            }
          });
        }

        if (selectedPhotos && selectedPhotos.length > 0) {
          selectedPhotos.forEach(photo => {
            if (photo.price) {
              price += parseFloat(photo.price) || 0;
            }
          });
        }
      } else {
        setSelectedSlot(''); // Reset the slot if the slot confirmation fails
      }
    }

    setPrice(price);
  }, [numberOfPersons, selectedSlot, theaterData, initialPrice, selectedPackageIndex, selectedDecoration, selectedCake, selectedRoses, selectedExtradecorations, selectedPhotos]);

  const handleSlotChange = (e) => {
    const newSlot = e.target.value;
    const selectedSlotData = theaterData.slots.find(slot => `${slot.start_time} to ${slot.end_time}` === newSlot);

    if (selectedSlotData) {
      const duration = calculateDuration(selectedSlotData.start_time, selectedSlotData.end_time);
      if (duration === 1.5) {
        const userConfirmed = toast.info(
          'You have selected a slot with 1.5 hours duration and will be charged accordingly. Proceed further if you are okay with it!'
        );
        if (userConfirmed) {
          setSelectedSlot(newSlot);
        }
      } else {
        setSelectedSlot(newSlot);
      }
    }
  };

  const handleOpenDecorationsModal = async () => {
    if (validateForm()) {
      try {
        const response = await axios.post('https://api.blingnbliss.in/api/get-addons-by-categoryId/5');
        if (response.data.success) {
          setAddons(response.data.data[0].addons);
          setCurrentStep("decorations");
          document.body.classList.add('modal-open');
        } else {
          toast.error("Failed to load decorations");
        }
      } catch (error) {
        toast.error("An error occurred while fetching decorations");
      }
    }

  };

  const handleOpenCakesModal = async () => {
    try {
      const response = await axios.post('https://api.blingnbliss.in/api/get-cakes-by-qty');
      // console.log("response", response);

      if (response.data && response.data.success) {
        // Access cakes data correctly
        setCakes(response.data.data);
        setCurrentStep("cakes");
      } else {
        toast.error("Failed to load cakes");
      }
    } catch (error) {
      toast.error("An error occurred while fetching cakes");
    }
  };

  const handleOpenRoseModal = async () => {
    try {
      const response = await axios.post('https://api.blingnbliss.in/api/get-addons-by-categoryId/2');
      if (response.data.success) {
        setRoses(response.data.data[0].addons);
        setCurrentStep("roses");
      } else {
        toast.error("Failed to load cakes");
      }
    } catch (error) {
      toast.error("An error occurred while fetching cakes");
    }
  };
  const handleExtradecorationsModal = async () => {
    try {
      const response = await axios.post('https://api.blingnbliss.in/api/get-addons-by-categoryId/1');
      if (response.data.success) {
        setExtradecorations(response.data.data[0].addons);
        setCurrentStep("extra");
      } else {
        toast.error("Failed to load decorations");
      }
    } catch (error) {
      toast.error("An error occurred while fetching decorations");
    }
  };
  const handleOpenPhotographyModal = async () => {
    try {
      const response = await axios.post('https://api.blingnbliss.in/api/get-addons-by-categoryId/6');
      if (response.data.success) {
        setPhotograhpy(response.data.data[0].addons);
        setCurrentStep("photograhpy");
      } else {
        toast.error("Failed to load cakes");
      }
    } catch (error) {
      toast.error("An error occurred while fetching cakes");
    }
  };
  const summaryData = async () => {
    setCurrentStep("summary");
  }





  const handlePayment = async () => {
    setLoading(true);

    // Get IDs from selected items
    const extraDecorationIds = selectedExtradecorations.map(decoration => decoration.addons_id);
    const roseIds = selectedRoses.map(roses => roses.addons_id);
    const photoIds = selectedPhotos.map(photos => photos.addons_id);
    const selectedPackage = theaterData.extraPackage.find(pkg => pkg.id === selectedPackageIndex);
    const packageTitle = selectedPackage ? selectedPackage.title : '';
    const packageDescription = selectedPackage ? selectedPackage.description : '';
    const packagePrice = selectedPackage ? selectedPackage.pirce : '';
    // console.log("is appiled line 513", isCouponApplied);

    const couponId = isCouponApplied ? couponDetails.coupon_id : '';
    const couponCode = isCouponApplied ? couponDetails.code : '';
    const couponvalue = isCouponApplied ? couponDetails.coupon_value : '';
    // console.log("");

    let adjustedPayableAmount = 0;
    if (formData.paymentType === 'totalAmount') {
      adjustedPayableAmount = 0;
    } else {
      adjustedPayableAmount = payable_amount;
    }
    // Concatenate all IDs into a single array
    const allAddonIds = [...extraDecorationIds, ...roseIds, ...photoIds];

    // Construct the data object
    const bookingData = {
      location: theaterData.location_id,
      theatre_id: theaterData.theater_id,
      theatre_name: theaterData.theater_name,
      start_date: selectedDate,
      price: price,
      time_slots: selectedSlot,
      slot_id: selectedSlotId,
      number_of_people: numberOfPersons,
      name1: formData.your_nickname,
      name2: formData.partner_nickname,
      led_number: formData.lednumber,
      led_letter: formData.ledletters,
      special_personName: formData.personName,
      decor_id: selectedDecoration?.addons_id || '',
      cake_name: selectedCake?.addons_name || '',
      cake_price: selectedCake?.price || 0,
      addons_id: allAddonIds,
      user_name: formData.username,
      package_title: packageTitle,
      package_description: packageDescription,
      package_price: packagePrice,
      whatsapp_number: formData.whatsappNumber,
      email: formData.mail,
      coupon_id: couponId,
      coupon_code: couponCode,
      coupon_value: couponvalue,
      payable_amount: adjustedPayableAmount
    
    };

    // console.log("booking", bookingData);

    try {
      const response = await axios.post('https://api.blingnbliss.in/api/storebookings', bookingData);
      const responseData = response.data[0]; // Assuming the response is an array with a single object
      const orderId = responseData.order_id;
      if (responseData.is_redirect && window.Cashfree) {
        const cashfree = window.Cashfree({
          mode: "production",
        });

        const checkoutOptions = {
          paymentSessionId: responseData.payment_session_id,
          redirectTarget: "_modal",
        };

        try {
          const result = await cashfree.checkout(checkoutOptions);
          // console.log("result line 364", result);

          if (result.error) {

            const res = await axios.post(`https://api.blingnbliss.in/api/cacelPayment/${orderId}`);
             console.log("paymnet is failed", res);
            toast.error("Payment error occurred. Please try again.");
          } else if (result.redirect) {
            console.log("line 367", result.redirect);

          } else if (result.paymentDetails) {
            // console.log("Payment has been completed, Check for Payment Status");
            console.log("line 371", result.paymentDetails)

            try {
              const paymentResponse = await axios.post(`https://api.blingnbliss.in/api/checkPaymentStatus/${responseData.order_id}`);
              const { status, message } = paymentResponse.data;

              if (status === "success") {
                toast.success(message);
                document.body.classList.remove('modal-open');
                navigate('/thank-you');
              } else if (status === "failed") {
                toast.warn(message);
              }
            } catch (error) {
              console.log("error line 382", error);
              // const cancelPaymentResponse = await axios.post(`https://api.blingnbliss.in/api/cacelPayment/${responseData.order_id}`)
              // console.log("cancelPaymentResponse",cancelPaymentResponse);
              toast.error("Payment error occurred. Please try again.")
            }
          }


        } catch (error) {
          // console.log("line 392", error);
          // toast.warn("Payment failed. Please try again.");
          toast.error("An error occurred during payment. Please try again.");
        }
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Booking failed. Please try again later.';
      toast.error(errorMessage);
    } finally {
      setLoading(false); // Stop loading after API call is complete
    }
  };



  const paymentProcess = () => {
    if (ProceedvalidateForm()) {
      setCurrentStep("paymentprocess");
    }
  }

  const handlePrevious = () => {
    if (currentStep === "cakes") {
      setCurrentStep("decorations");
    } else if (currentStep === "roses") {
      setCurrentStep("cakes");

    }
    else if (currentStep === "extra") {
      setCurrentStep("roses");
    } else if (currentStep === "photograhpy") {
      setCurrentStep("extra");
    }
    else if (currentStep === "summary") {
      setCurrentStep("photograhpy")
    }
    else if (currentStep === "paymentprocess") {
      setCurrentStep("summary")
    }
  };


  const handleAddonSelect = (addon, type) => {
    let newPrice = price;

    if (type === "decoration") {
      if (selectedDecoration?.addons_id === addon.addons_id) {
        newPrice -= parseFloat(selectedDecoration.price);
        setSelectedDecoration(null);
      } else {
        if (selectedDecoration) {
          newPrice -= parseFloat(selectedDecoration.price);
        }
        setSelectedDecoration(addon);
        newPrice += parseFloat(addon.price);
      }
    } else if (type === "cake") {
      setShowNameInput(true);
      if (selectedCake?.addons_id === addon.addons_id) {
        newPrice -= parseFloat(selectedCake.price);
        setSelectedCake(null);
      } else {
        if (selectedCake) {
          newPrice -= parseFloat(selectedCake.price);
        }
        setSelectedCake(addon);
        newPrice += parseFloat(addon.price);
      }
    } else if (type === "rose") {
      const updatedRoses = selectedRoses.some((rose) => rose.addons_id === addon.addons_id)
        ? selectedRoses.filter((rose) => rose.addons_id !== addon.addons_id)
        : [...selectedRoses, addon];

      if (selectedRoses.some((rose) => rose.addons_id === addon.addons_id)) {
        newPrice -= parseFloat(addon.price);
      } else {
        newPrice += parseFloat(addon.price);
      }

      setSelectedRoses(updatedRoses);
    } else if (type === "extradecoration") {
      const updatedExtradecorations = selectedExtradecorations.some((extradecoration) => extradecoration.addons_id === addon.addons_id)
        ? selectedExtradecorations.filter((extradecoration) => extradecoration.addons_id !== addon.addons_id)
        : [...selectedExtradecorations, addon];

      if (selectedExtradecorations.some((extradecoration) => extradecoration.addons_id === addon.addons_id)) {
        newPrice -= parseFloat(addon.price);
      } else {
        newPrice += parseFloat(addon.price);
      }

      setselectedExtradecorations(updatedExtradecorations);
    } else if (type === "photo") {
      const updatedPhotos = selectedPhotos.some((photo) => photo.addons_id === addon.addons_id)
        ? selectedPhotos.filter((photo) => photo.addons_id !== addon.addons_id)
        : [...selectedPhotos, addon];

      if (selectedPhotos.some((photo) => photo.addons_id === addon.addons_id)) {
        newPrice -= parseFloat(addon.price);
      } else {
        newPrice += parseFloat(addon.price);
      }

      setSelectedPhotos(updatedPhotos);
    }

    setPrice(newPrice);
  };




  const handleNext = () => {
    // Log current step for debugging
    // console.log("Current Step:", currentStep);

    // Variable to track if there are validation errors
    let hasError = false;


    if (currentStep === "decorations") {


      // Additional validation for "decorations" step if a decoration is selected
      if (selectedDecoration) {
        if (nameLabel && (!formData.your_nickname || formData.your_nickname.length > 8)) {
          toast.error('Please enter a name with a maximum of 8 characters.');
          hasError = true;
        }
        if (partnerLabel && (!formData.partner_nickname || formData.partner_nickname.length > 8)) {
          toast.error('Please enter your partner\'s nickname with a maximum of 8 characters.');
          hasError = true;
        }
      }

      // Proceed to the next modal if no errors
      if (!hasError) {
        handleOpenCakesModal();
      }

    } else if (currentStep === "cakes") {
      // Check if the person name field is required and validate it
      if (selectedCake) {
        if (showNameInput && !formData.personName.trim()) {
          toast.error('Please enter the person\'s name.');
          hasError = true;
        }
      }

      if (!hasError) {
        handleOpenRoseModal();
      }

    } else if (currentStep === "roses") {
      // console.log("Entered roses step");
      handleExtradecorationsModal();

    } else if (currentStep === "extra") {
      const numberRegex = /^[0-9]+$/;
      const letterRegex = /^[a-zA-Z]+$/;

      // console.log("formdata ............", formData.lednumber);
      selectedExtradecorations.forEach(item => {
        if (item.addons_name === 'LED Numbers') {
          if (!formData.lednumber.trim()) {
            toast.error('Please enter the LED number.');
            hasError = true;
          } else if (!numberRegex.test(formData.lednumber.trim())) {
            toast.error('LED number must contain only numbers.');
            hasError = true;
          }
        }

        // Validate "LED Letters"
        if (item.addons_name === 'LED Letters') {
          if (!formData.ledletters.trim()) {
            toast.error('Please enter the LED letters.');
            hasError = true;
          } else if (!letterRegex.test(formData.ledletters.trim())) {
            toast.error('LED letters must contain only alphabetic characters.');
            hasError = true;
          }
        }
      });


      // Proceed to the next modal if no errors
      if (!hasError) {
        handleOpenPhotographyModal();
      }

    } else if (currentStep === "photography") {
      summaryData();

    } else if (currentStep === "summary") {
      paymentProcess();
    }
  };


  const getInputLabel = () => {
    if (!selectedDecoration) return {};
    switch (selectedDecoration.addons_name) {
      case 'Birthday':
        return { nameLabel: 'Nick Name of the Birthday person:', partnerLabel: '' };
      case 'Anniversary':
        return { nameLabel: 'Enter Your Nick Name:', partnerLabel: 'Enter Partner\'s Nick Name:' };
      case 'Bride to be':
        return { nameLabel: 'Bride to be’s Nick Name', partnerLabel: '' };
      case 'Groom to be':
        return { nameLabel: 'Groom to be’s Nick Name', partnerLabel: '' };
      case 'Mom to be':
        return { nameLabel: 'Mom to be’s Nick Name', partnerLabel: '' };
      case 'Farewell':
        return { nameLabel: 'Nick Name of the Special person', partnerLabel: '' };
      case 'Romantic Date':
        return { nameLabel: 'Enter Your Nick Name:', partnerLabel: 'Enter Partner\'s Nick Name:' };
      case 'Marriage proposal':
        return { nameLabel: 'Enter Your Nick Name:', partnerLabel: 'Enter Partner\'s Nick Name:' };
      case 'Love Proposal':
        return { nameLabel: 'Enter Your Nick Name:', partnerLabel: 'Enter Partner\'s Nick Name:' };
      case 'Baby Shower':
        return { nameLabel: 'Nick Name of the baby', partnerLabel: '' };
      case 'Congratulations':
        return { nameLabel: 'Nick Name of the special person', partnerLabel: '' };

      default:
        return { nameLabel: '', partnerLabel: '' };
    }
  };
  const { nameLabel, partnerLabel } = getInputLabel();


  const getInputLabelExra = () => {
    let showLedLetters = false;
    let showLedNumber = false;

    selectedExtradecorations.forEach((addon) => {
      if (addon.addons_name === 'LED Letters') {
        showLedLetters = true;
      }
      if (addon.addons_name === 'LED Numbers') {
        showLedNumber = true;
      }
    });

    return {
      ledLetter: showLedLetters ? 'Enter led letters:' : '',
      ledNumber: showLedNumber ? 'Enter led number:' : ''
    };
  };

  useEffect(() => {
    const { ledLetter, ledNumber } = getInputLabelExra();
    setLedLetter(ledLetter);
    setLedNumber(ledNumber);
  }, [selectedExtradecorations]);




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <>
      <div className="page-content" style={{ background: '#CBBBDD' }}>
        {/* <div className="page-title-area" style={{ backgroundImage: 'url(/images/refundimg.png)' }}> */}
        {/* < div className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <h1>Theatre Details</h1>
            </div>
          </div>
        </div> */}
        <div className="why-choose-us-two selected-theater-data" style={{ background: '#CBBBDD', paddingTop: '230px' }}>

          <div className="row m-0 h-100 align-items-center selected-theatre-data ">
            <div className="col-lg-1 "></div>
            <div className="col-lg-5 col-md-7 p-4 theatre-details ">
              <div className='contact-box ' style={{ backgroundColor: '#5a0c97' }}>
                <div className="section-title selected-section">
                  <h5 className="bi bi-geo-alt text-primary location-name">{theaterData.location_name}</h5>
                  <h2 className="location-name" >{theaterData.theater_name}</h2>
                  {/* <div className="bar"></div> */}
                  <ul className="selected-theatre-details">
                    {descriptionPoints.map((point, index) => (
                      <li key={index} className="m-2">{point}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-5 col-md-6 selected-images">
              {theaterData.galleryList && theaterData.galleryList.map((gallery, i) => (
                <div className="gallery-section selected-theatre-contact-box" key={i}>
                  <Carousel>
                    {Object.values(gallery).map((image, index) => (
                      <Carousel.Item key={index} className="carousel-item1">
                        <img
                          src={`https://api.blingnbliss.in/${image}`}
                          alt={`Gallery Image ${index + 1}`}
                          className="d-block w-100"
                          style={{ objectFit: 'cover' }}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              ))}
            </div> */}
            <div className="col-lg-5 col-md-6 selected-images">
              {theaterData.galleryList && theaterData.galleryList.map((gallery, i) => (
                <div className="gallery-section selected-theatre-contact-box" key={i}>
                  <Carousel>
                    {/* Parse image2 from JSON string and map through the images */}
                    {gallery.image2 && JSON.parse(gallery.image2).map((image, index) => (
                      <Carousel.Item key={index} className="carousel-item1">
                        <img
                          src={`https://api.blingnbliss.in/${image}`}
                          alt={`Gallery Image ${index + 1}`} // Adjusted for proper labeling
                          className="d-block w-100"
                          style={{ objectFit: 'cover' }}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              ))}
            </div>


          </div>

          <div className="container">
            <div className="row  h-100 ">
              {theaterData.extraPackage && theaterData.extraPackage.length > 0 && (
                <div className="extra-package-section">

                  <div className="row package-data">
                    <h3 className="packages-names m-2">Packages</h3>
                    {theaterData.extraPackage.map((pkg) => (
                      <div
                        key={pkg.id}
                        className={`col-lg-6 col-md-7 select-date-package  p-4 d-flex  ${selectedPackageIndex === pkg.id ? 'selected-package' : ''
                          }`}
                        onClick={() => handlePackageSelect(pkg.id)}
                      >
                        <div className={`contact-box ${selectedPackageIndex === pkg.id ? 'selected-package' : ''}`}>
                          <div className="package-text">
                            <h4 className="packages">Title :  {pkg.title}</h4>
                            <h4 className="packages">Price : {pkg.price}</h4>
                            <p className="packages">Description : {pkg.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>


          <div className="schedule-area schedule-style-three bg-image ptb-120 select-slots" style={{ background: '#CBBBDD' }}>
            <div className="container" >
              <div className="row" >
                <div className="col-lg-6 col-md-12 slots-selection">
                  <div className="row">
                    <div className="col-lg-8 col-md-5 select-date-theatre">
                      <div className="form-group">
                        <label htmlFor="date">Select the Date</label>
                        <input
                          type="date"
                          className="form-control"
                          name="date"
                          id="date"
                          min={today}
                          value={selectedDate}
                          onChange={(e) => setSelectedDate(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-7 slots-theatre">
                      <div className="form-group">
                        <label htmlFor="slots">Slots</label>
                        <select
                          className="form-control"
                          name="slots"
                          id="slots"
                          required
                          value={selectedSlot}
                          onChange={handleSlotChange}
                        >
                          <option value="" disabled>
                            Select a slot*
                          </option>
                          {theaterData.slots &&
                            theaterData.slots.map((slot) => (
                              <option
                                key={slot.slot_id}
                                value={`${slot.start_time} to ${slot.end_time}`}
                                disabled={!slot.is_available}
                              >
                                {slot.start_time} to {slot.end_time}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 ">
                  <div className="row m-2 taxes">
                    <div className="col-lg-8 col-md-6 mt-2">
                      <div className="form-group">
                        <div className="price-info">
                          <label>Inclusive of all taxes</label>
                          <h1>₹{selectedSlot ? price : 0}</h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-7 no-of-persons">
                      <div className="form-group">
                        <label htmlFor="persons">No. of Persons *</label>
                        <select
                          className="form-control"
                          name="persons"
                          id="persons"
                          required
                          value={numberOfPersons}
                          onChange={(e) => setNumberOfPersons(e.target.value)}
                        >
                          <option value="" disabled>
                            Select number of persons
                          </option>
                          {theaterData.extraChargePersons && theaterData.extraChargePersons.map((charge) => (
                            <option key={charge.person} value={charge.person}>
                              {charge.person}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-4 booknow-btn">
                    <button
                      type="button"
                      className="btn btn-primary book-now-btn"
                      onClick={handleOpenDecorationsModal}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
        {currentStep === "decorations" && (
          <div className="modal show " tabIndex={-1} style={{ display: 'block' }}>
            <div className="modal-dialog modal-xl modal-sm modal-md">
              <div className="modal-content">
                {/* <div className="modal-header">
                  <h5 className="modal-title me-auto">Decoration(Optional) Total Amount : ₹{price} </h5>
                  <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                </div> */}
                <div className="modal-header d-flex justify-content-between align-items-center">
                  <h5 className="modal-title  decoration-title">Decoration</h5>
                  <span className="total-price">Total: ₹<strong>{price}</strong></span>
                  <button type="button" className="btn-close btn-close-white" onClick={handleCloseModal}></button>
                </div>

                <div className="modal-body">
                  <div className="row">
                    {addons.map((addon: Addon) => (
                      <div className="col-xl-3 col-lg-4 col-md-3 col-sm-3 mb-3" key={addon.addons_id}>
                        <div className={`${selectedDecoration?.addons_id === addon.addons_id ? 'selected' : ''}`}
                          key={addon.addons_id}
                          onClick={() => handleAddonSelect(addon, "decoration")}
                          style={{ cursor: 'pointer' }}>
                          <div className="single-speakers-box">
                            <img
                              src={`https://api.blingnbliss.in/${addon.image}`}
                              alt={addon.addons_name}
                              width={300}
                              height={200}
                              className="seaker-img"
                              layout="responsive"
                              objectFit="cover"
                            // onClick={() => handleAddonSelect(addon)}
                            // style={{ cursor: 'pointer' }}
                            />
                            <div className="card-body1">
                              <h6>{addon.addons_name}</h6>
                              <h6>Price: ₹<strong>{addon.price}</strong></h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {selectedDecoration && (
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-12 text-center mb-3">
                            <h5 className="nicknames">Please enter the following details:</h5>
                          </div>
                          {nameLabel && (
                            <div className="col-lg-4 col-md-7 col-sm-6 mb-3">
                              <div className="form-group">
                                <label htmlFor="name">{nameLabel}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="your_nickname"
                                  id="your_nickname"
                                  value={formData.your_nickname}
                                  onChange={handleChange}
                                  maxLength={8}
                                />
                              </div>
                            </div>
                          )}
                          {partnerLabel && (
                            <div className="col-lg-4 col-md-7 col-sm-6 mb-3">
                              <div className="form-group">
                                <label htmlFor="partner_nick">{partnerLabel}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="partner_nickname"
                                  id="partner_nickname"
                                  value={formData.partner_nickname}
                                  onChange={handleChange}
                                  maxLength={8}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}


                  </div>
                  <h4 >Note : </h4>
                  <h6 >Decorations are not customizable. Please select predefined add-ons in the next windows.</h6>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary mt-3" onClick={handleCloseModal} >
                    Back
                  </button>
                  <button type="button" className="btn btn-primary mt-3" onClick={handleNext}  disabled={selectedDecoration ===null}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === "cakes" && (
          <div className="modal show" tabIndex={-1} style={{ display: 'block' }}>
            <div className="modal-dialog modal-xl modal-sm modal-custom">
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                  <h5 className="modal-title decoration-title">Cakes</h5>
                  <span className="toggle-text m-2">Swipe the toggle for 1 kg cakes </span>

                  <div className="modal-title form-check form-switch toggle-cakes">

                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="quantityToggle"
                      checked={selectedQuantity === "0.50"}
                      onChange={() => setSelectedQuantity(selectedQuantity === "1.00" ? "0.50" : "1.00")}
                    />
                    <label className="form-check-label summary-text" htmlFor="quantityToggle">
                      {selectedQuantity === "1.00" ? "1 Kg" : "1/2 Kg"}

                    </label>

                  </div>

                  <span className="cake-total-price">Total: ₹<strong>{price}</strong></span>
                  <button type="button" className="btn-close btn-close-white" onClick={handleCloseModal}></button>

                </div>
                <div className="modal-body">
                  <div className="row">
                    {/* Filter cakes based on selected quantity */}
                    {cakes
                      .filter(cake => cake.qty === selectedQuantity)
                      .map((cake) => (
                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-3 mb-3" key={cake.addons_id}>
                          <div
                            className={`${selectedCake?.addons_id === cake.addons_id ? 'selected-cake' : ''}`}
                            onClick={() => handleAddonSelect(cake, "cake")}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className="single-speakers-box">
                              <img
                                src={`https://api.blingnbliss.in/${cake.image}`}
                                alt={cake.addons_name}
                                width={300}
                                height={200}
                                className="seaker-img"
                                layout="responsive"
                                objectFit="cover"
                              />
                              <div className="card-body1 ">
                                <h6 >{cake.addons_name}</h6>
                                <h6 className="">Price: ₹<strong>{cake.price}</strong></h6>
                                <h6 className="add-cake"> Quantity: {cake.qty === "0.50" ? "1/2 Kg" : cake.qty === "1.00" ? "1 Kg" : `${cake.qty} Kg`}</h6>

                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {showNameInput && (
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-4 col-md-7 col-sm-6 text-center mb-3">
                            <div className="form-group">
                              <label htmlFor="name1">Enter the person name:</label>
                              <input
                                type="text"
                                className="form-control"
                                id="person_name"
                                name="personName"
                                value={formData.personName}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <h4>NOTE :</h4>
                  <h6>Above images are for demonstration purposes only. Actual cake may look different.</h6>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary mt-3" onClick={handlePrevious}>
                    Back
                  </button>
                  <button type="button" className="btn btn-primary mt-3" onClick={handleNext}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === "roses" && (
          <div className="modal show" tabIndex={-1} style={{ display: 'block' }}>
            <div className="modal-dialog modal-xl modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title decoration-title">Roses</h5>
                  <span className="rose-total-price">Total: ₹<strong>{price}</strong></span>
                  <button type="button" className="btn-close btn-close-white" onClick={handleCloseModal}></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    {roses.map((rose: Addon) => (
                      <div className="col-xl-3 col-lg-4 col-md-3 col-sm-3 mb-3" key={rose.addons_id}>
                        {/* <div className={`${selectedRoses?.addons_id === rose.addons_id ? 'selected' : ''}`} */}
                        <div
                          className={`${selectedRoses.some((r) => r.addons_id === rose.addons_id) ? 'selected' : ''}`}
                          onClick={() => handleAddonSelect(rose, "rose")}
                          style={{ cursor: 'pointer' }}>
                          <div className="single-speakers-box">
                            <img
                              src={`https://api.blingnbliss.in/${rose.image}`}
                              alt={rose.addons_name}
                              width={300}
                              height={200}
                              className="seaker-img"
                              layout="responsive"
                              objectFit="cover"
                            />
                            <div className="card-body1">
                              <h6 >{rose.addons_name}</h6>
                              <h6>Price: ₹<strong>{rose.price}</strong></h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary mt-3" onClick={handlePrevious}>
                    Back
                  </button>
                  {/* <button type="button" className="btn btn-primary mt-3" onClick={handleExtradecorationsModal}>
                    Next
                  </button> */}
                  <button type="button" className="btn btn-primary mt-3" onClick={handleNext} >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {currentStep === "extra" && (
          <div className="modal show" tabIndex={-1} style={{ display: 'block' }}>
            <div className="modal-dialog modal-xl modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title decoration-title">Extra Decorations</h5>
                  <span className="extra-total-price">Total: ₹<strong>{price}</strong></span>
                  <button type="button" className="btn-close btn-close-white" onClick={handleCloseModal}></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    {extradecorations.map((extradecoration: Addon) => (
                      <div className="col-xl-3 col-lg-4 col-md-3  col-sm-3 mb-3" key={extradecoration.addons_id}>
                        <div className={`${selectedExtradecorations.some((extradec) => extradec.addons_id === extradecoration.addons_id) ? 'selected' : ''}`}
                          onClick={() => handleAddonSelect(extradecoration, "extradecoration")}
                          style={{ cursor: 'pointer' }}>
                          <div className="single-speakers-box">
                            <img
                              src={`https://api.blingnbliss.in/${extradecoration.image}`}
                              alt={extradecoration.addons_name}
                              width={300}
                              height={200}
                              layout="responsive"
                              className="seaker-img"
                              objectFit="cover"
                            />
                            <div className="card-body1">
                              <h6>{extradecoration.addons_name}</h6>
                              <h6>Price: ₹<strong>{extradecoration.price}</strong></h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {(ledLetter || ledNumber) && (
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-12 text-center mb-3">
                            <h5 className="nicknames">Please enter the following details:</h5>
                          </div>

                          {ledNumber && (
                            <div className="col-lg-4 col-md-6 col-sm-6 mb-3">
                              <div className="form-group">
                                <label htmlFor="lednumber">{ledNumber}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="lednumber"
                                  id="lednumber"
                                  value={formData.lednumber}
                                  onChange={handleChange}
                                  maxLength={2}
                                />
                              </div>
                            </div>
                          )} {ledLetter && (
                            <div className="col-lg-4 col-md-6 col-sm-6 mb-3">
                              <div className="form-group">
                                <label htmlFor="ledletters">{ledLetter}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ledletters"
                                  id="ledletters"
                                  value={formData.ledletters}
                                  onChange={handleChange}
                                  maxLength={6}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <h4>NOTE :</h4>
                  <ol>
                    <li>The props customers opt for are for use during the event only and are not allowed to be taken home.</li></ol>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary mt-3" onClick={handlePrevious}>
                    Back
                  </button>
                  <button type="button" className="btn btn-primary mt-3" onClick={handleNext} >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {currentStep === "photograhpy" && (
          <div className="modal show" tabIndex={-1} style={{ display: 'block' }}>
            <div className="modal-dialog modal-xl modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title decoration-title">Photography(soft copies)</h5>
                  <span className="photo-total-price">Total: ₹<strong>{price}</strong></span>
                  {/* <span className="modal-title-amonut" style={{ marginLeft: '10px' }}>Total Amount : ₹{price}</span> */}
                  <button type="button" className="btn-close btn-close-white" onClick={handleCloseModal}></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    {photograhpy.map((photo: Addon) => (
                      <div className="col-xl-3 col-lg-4 col-md-3 col-sm-3 mb-3" key={photo.addons_id}>
                        <div className={`${selectedPhotos.some((photos) => photos.addons_id === photo.addons_id) ? 'selected-photo' : ''}`}
                          onClick={() => handleAddonSelect(photo, "photo")}
                          style={{ cursor: 'pointer' }}>
                          <div className="single-speakers-box">
                            <img
                              src={`https://api.blingnbliss.in/${photo.image}`}
                              alt={photo.addons_name}
                              width={300}
                              height={200}
                              className="seaker-img"
                              layout="responsive"
                              objectFit="cover"
                            />
                            <div className="card-body1">
                              <h6 className="m-2">{photo.addons_name}</h6>
                              <h6>Price: ₹<strong>{photo.price}</strong></h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <h4>NOTE :</h4>
                  <h6> Timing of the photography is according to the availability of the photographer.</h6>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary mt-3" onClick={handlePrevious}>
                    Back
                  </button>
                  <button type="button" className="btn btn-primary mt-3" onClick={summaryData} >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {currentStep === "summary" && (
          <div className="modal show" tabIndex={-1} style={{ display: 'block' }}>
            <div className="modal-dialog modal-xl modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="btn-close btn-close-white" onClick={handleCloseModal}></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-5 col-md-9 summary-section ">
                      <h3 className="summary-text">{theaterData.theater_name}</h3>
                      <h5 className="summary-text">
                        <i className="bi bi-currency-rupee summary-text"></i> {price} (inclusive of all taxes)
                      </h5 >
                      <h5 className="summary-text">
                        <i className="bi bi-calendar"></i> {selectedDate ? formatDateToIndian(selectedDate) : 'No date selected'}
                      </h5>

                      <h5 className="summary-text">
                        <i className="bi bi-clock"></i> {selectedSlot}
                      </h5>
                      <h5 className="summary-text">
                        <i className="bi bi-people"></i> {numberOfPersons}
                      </h5>

                      <h5 className="summary-text">
                        <i className="bi bi-cake2"></i>
                        {selectedCake?.addons_name ? `${selectedCake.addons_name} (${selectedCake.price}/-)` : '  : None'}
                      </h5>


                      <h5 className="summary-text">
                        <i className="bi bi-emoji-smile"></i> {selectedDecoration?.addons_name || 'None'}
                      </h5>

                      <h4 className="summary-text">Add Ons:</h4>
                      {(
                        selectedExtradecorations.length === 0 &&
                        selectedRoses.length === 0 &&
                        selectedPhotos.length === 0
                      ) ? (
                        <p className="summary-text">None</p>
                      ) : (
                        <div>
                          {selectedExtradecorations.length > 0 && (
                            <div className="extra-decorations summary-text">

                              {selectedExtradecorations.map((item, index) => {
                                if (item.addons_name === 'LED Letters') {
                                  return (

                                    <div key={index} className="decoration-item summary-text">
                                      {item.addons_name} ({formData.ledletters})
                                    </div>
                                  );
                                } else if (item.addons_name === 'LED Numbers') {
                                  return (
                                    <div key={index} className="decoration-item summary-text">
                                      {item.addons_name} ({formData.lednumber})
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={index} className="decoration-item summary-text">
                                      {item.addons_name} ({item.price}/-)
                                    </div>
                                  );
                                }
                              })}
                            </div>

                          )}
                          {selectedRoses.length > 0 && (
                            <div className="extra-decorations">
                              {selectedRoses.map((roses, index) => (
                                <div key={index} className="decoration-item summary-text">
                                  {roses.addons_name} ({roses.price}/-)
                                </div>
                              ))}
                            </div>
                          )}
                          {selectedPhotos.length > 0 && (
                            <div className="extra-decorations summary-text">
                              {selectedPhotos.map((photos, index) => (
                                <div key={index} className="decoration-item summary-text">
                                  {photos.addons_name} ({photos.price}/-)
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-9 customer-details">
                      <h5 className="details-text">Enter your details to proceed further</h5>
                      <form >
                        <div className="form-group">
                          <label htmlFor="username">Name *</label>
                          <input
                            type="text"
                            id="username"
                            name="username"
                            className="form-control"
                            value={formData.username}
                            onChange={handleChange}

                          />
                          {errors.username && <div className="text-danger">{errors.username}</div>}
                        </div>

                        <div className="form-group mt-2">
                          <label htmlFor="whatsappNumber">WhatsApp Number</label>
                          <input
                            type="text"
                            id="whatsappNumber"
                            name="whatsappNumber"
                            maxLength="10"
                            className="form-control"
                            value={formData.whatsappNumber}
                            onChange={handleChange}

                          />
                          {errors.whatsappNumber && <div className="text-danger">{errors.whatsappNumber}</div>}
                        </div>
                        <div className="form-group mt-2">
                          <label htmlFor="mail">Email</label>
                          <input
                            id="mail"
                            name="mail"
                            className="form-control"
                            value={formData.mail}
                            onChange={handleChange}
                          />
                          {errors.mail && <div className="text-danger">{errors.mail}</div>}
                        </div>
                       
                        <div className="form-group mt-2 d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control me-2"
                            placeholder="Enter Coupon"
                            value={coupon}
                            // onChange={(e) => setCoupon(e.target.value)}
                            onChange={handleCouponChange}
                            disabled={isCouponApplied}
                          />
                          <div className="w-100" style={{ whiteSpace: 'nowrap' }}>
                            <button type="button" className="btn btn-primary" onClick={ApplyCouponCard}>
                              {isCouponApplied ? 'Remove Coupon' : 'Apply Coupon'}
                            </button>
                          </div>
                        </div>

                        {responseMessage && (
                          <div className={`mt-2 ${isSuccess ? 'text-success' : 'text-danger'}`}>
                            {responseMessage}
                          </div>
                        )}
                        <div className="form-check-group mt-2">
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              id="paypalAmount"
                              name="paymentType"
                              value="paypalAmount"
                              className="form-check-input"
                              checked={formData.paymentType === 'paypalAmount'}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="paypalAmount" className="form-check-label">Advance Amount <span className="ms-2">(₹{payable_amount})</span></label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              id="totalAmount"
                              name="paymentType"
                              value="totalAmount"
                              className="form-check-input"
                              checked={formData.paymentType === 'totalAmount'}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="totalAmount" className="form-check-label">Total amount <span className="ms-2">(₹{price})</span></label>
                          </div>
                        </div>
                        <div>
                          <p>We collect an advance amount of ₹ 700 + convenience fee (₹50) and also cake price to book the slot. The remaining bill will be collected at the time of check-out.</p>
                        </div>
                        <div className="button-group mt-2 btn-footer">
                          <button type="button" className="btn btn-primary mt-3 terms-back-btn" onClick={handlePrevious}>
                            Back
                          </button>
                          <button type="button" className="btn btn-primary mt-3 payment-btn" onClick={paymentProcess}>
                            Proceed To Pay
                          </button>

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {currentStep === "paymentprocess" && (
          <div className="modal show" tabIndex={-1} style={{ display: 'block' }}>
            <div className="modal-dialog modal-xl modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title title-terms">Important Instructions</h5>
                  {/* <span className="modal-title-amonut" style={{ marginLeft: '10px' }}>Total Amount : ₹{price}</span> */}
                  <button type="button" className="btn-close btn-close-white" onClick={handleCloseModal}></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12 conditions">
                      <p className="terms">Refund Policy: A partial advance amount will be refunded if you cancel the slot at least 72 hours prior to your booking time.</p>
                      <ol>
                        <li>
                          Smoking and Consumption of Alcohol is strictly prohibited inside the Theaters.
                        </li>
                        <li>Party poppers, foam, and champagne are not allowed inside the theaters due to their sensitivity.</li>
                        <li>Outside food is strictly prohibited to protect the carpets and recliners inside the theaters.</li>
                        <li>Damaging the decorations in the theater (including plucking flowers, moving items from their original places, and blasting balloons) will incur a high penalty.</li>
                        <li>Damaging the screen will also result in a significant penalty.</li>
                        <li>The management reserves the right to admission.</li>
                      </ol>
                      <div className="d-flex align-items-center justify-content-center">
                        <input type="checkbox" id="agreeCheckbox" onChange={handleCheckboxChange} />
                        <label htmlFor="agreeCheckbox" className="agree-check">I agree to all the above conditions.</label>
                      </div>
                    </div>

                  </div>


                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary mt-3" onClick={handlePrevious}>
                    Back
                  </button>
                  {/* <button type="button" className="btn btn-primary mt-3" onClick={handlePayment} disabled={!isChecked}>
                  Confirm & Pay Advance
                  </button> */}
                  <button
                    type="button"
                    className="btn btn-primary mt-3 position-relative"
                    onClick={handlePayment}
                    disabled={!isChecked}
                  >
                    {loading ? (
                      <div className="spinner-overlay">
                        <ClipLoader color="#3498db" loading={loading} size={20} />
                      </div>
                    ) : (
                      'Confirm & Pay Advance'
                    )}

                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectedTheater;
