"use client";
import React from "react";


const TermsAndConditions = () => {
    return (
        <div className="page-content">

            <div className="faq-area ptb-120" style={{ background: '#CBBBDD', paddingTop: '280px' }}>

                <div className="faq-area  m-2" >

                    <div className="container">

                        <div className="faq-accordion">
                            <div className="section-title addon-section">
                                <h2>Terms&Conditions</h2>
                            </div>
                            <div className="accordion">
                                <div className="accordion__item">
                                    <div className="accordion__heading" aria-level="3">
                                        <div className="accordion__button" aria-expanded="false">
                                            Customers are required to arrange their own content source for the viewing experience
                                        </div>
                                    </div>
                                    <div className="accordion__panel" hidden="">
                                        <p>Customers are strictly advised not to stream any content that is illegal or prohibited according to Indian laws.
                                            Any violation of this condition will absolve us of any liability for resulting consequences,
                                            and future bookings will not be permitted for such customers.</p>
                                    </div>
                                </div>
                                <div className="accordion__item">
                                    <div className="accordion__heading" aria-level="3">
                                        <div className="accordion__button" aria-expanded="false">
                                            In case of any technical interruption from our end, appropriate refund will be provided.
                                        </div>
                                    </div>
                                    <div className="accordion__panel" hidden="">
                                        <p> In the event of projector, laptop, internet access, or sound system failure,
                                            we will reimburse the paid amount, deducting the rent for the duration prior to the interruption.
                                            Your satisfaction is our priority, and we strive to ensure a seamless experience.</p>
                                    </div>
                                </div>
                                <div className="accordion__item">
                                    <div className="accordion__heading" aria-level="3">
                                        <div className="accordion__button" aria-expanded="false">
                                            The booking period includes the time needed for setup and check-out.
                                        </div>
                                    </div>
                                    <div className="accordion__panel" hidden="">
                                        <p> Customers are kindly requested to ensure that they and their belongings are fully vacated from the theater premises by the specified end time.</p>
                                    </div>
                                </div>
                                <div className="accordion__item">
                                    <div className="accordion__heading" aria-level="3">
                                        <div className="accordion__button" aria-expanded="false">
                                            Customers are advised not to alter or modify the setup of any technical equipment provided.
                                        </div>
                                    </div>
                                    <div className="accordion__panel" hidden="">
                                        <p> To ensure optimal functionality, we kindly ask customers not to make any alterations or modifications to the setup of the projector,
                                            screen, laptop, or sound system.
                                            If any adjustments are required, our dedicated team will handle them for you..</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions;