import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="copyright-area">
                            <div className="logo">
                                <Link to="/">
                                    <img
                                        src="/images/blishlogo.png"
                                        alt="logo"
                                        width="120"
                                        height="38"
                                    />
                                </Link>
                            </div>
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/addons">Add-ons</Link>
                                </li>
                                <li>
                                    <Link to="/gallery">Gallery</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to="/refund-policy">Refund Policy</Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/terms-conditions">Terms & Conditions</Link>
                                </li>
                            </ul>
                            <div className="single-footer-widget">
                                <ul className="social-links m-2">
                                    <li>
                                        <a
                                            href="https://www.facebook.com/share/TW46ey7cQ9WE4neZ/?mibextid=qi2Omg"
                                            className="facebook"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="bi bi-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.youtube.com/@BlingnBliss"
                                            className="linkedin"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="bi bi-youtube"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/bling_n_bliss_/"
                                            className="instagram"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="bi bi-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>© 2024 Bling n Bliss | All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
