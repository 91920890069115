"use client";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/ContactUsPage.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// ContactInfo Component


const ContactInfo = () => {
    return (
        <div >
            <div className="row justify-content-center contact-details" >
                <div className="col-lg-4 col-md-7 ">
                    <div className="contact-box">
                        <div className="icon">
                            <i className="bi bi-telephone"></i>
                        </div>
                        <div className="content">
                            <h4>Call Us</h4>
                            <p>Kompally +91 81216 97022</p>
                            <p>Lb Nagar +91 79955 72167</p>

                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-7">
                    <div className="contact-box">
                        <div className="icon">
                            <i className="bi bi-envelope-check-fill"></i>
                        </div>
                        <div className="content">
                            <h4>Email Us</h4>
                            <p>blingnbliss1@gmail.com</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-7">
                    <div className="contact-box">
                        <div className="icon">
                            <div className='text-center' >
                                <a href="https://maps.app.goo.gl/wDaXZDGAbab5huuj6" target="_blank" className="d-inline-flex align-items-center">
                                    <i className="bi bi-geo-alt text-center"></i>

                                </a>
                            </div>
                        </div>
                        <div className="content">
                            <h4>Head Office</h4>
                            {/* <p>20-2, Beside St. Martin's Engg college, Kompally, Kaziguda, Hyderabad, Telangana 500100</p> */}
                            <p>4-6-137/w/182, plot no 182/p, Sai nagar west, Allwyn colony, kukatpally, Hyderabad, Telangana 500072</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};


const ContactForm = () => {
    const [formData, setFormData] = useState({
        contact_name: '',
        contact_email: '',
        contact_number: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const [errors, setErrors] = useState({});
    const validate = () => {
        const newErrors = {};
        const phoneRegex = /^[0-9]{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!formData.contact_name.trim()) {
            newErrors.contact_name = "Name is required.";
        }

        if (!formData.contact_email.trim() || !emailRegex.test(formData.contact_email)) {
            newErrors.contact_email = "Email  is required.";
        }

        if (!formData.contact_number.trim() || !phoneRegex.test(formData.contact_number)) {
            newErrors.contact_number = "Phone number is required.";
        }

        if (!formData.message.trim()) {
            newErrors.message = "Message is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) {
            return;
        }
        try {
            const response = await axios.post('https://api.blingnbliss.in/api/store-contact', formData);
            if (response.data.message) {
                toast.success(response.data.message);
            }
        } catch (error) {
            console.error("Error submitting form", error);
        }
    };

    return (
        <div className="contact-area ptb-120" style={{ background: '#CBBBDD', paddingTop: '230px' }}>
            <div className="container">
                {/* ContactInfo */}
                <ContactInfo />
                <div className="row h-100 align-items-center contact-form contact-leave-meassage">

                    <div className="col-lg-4 col-md-12 contact-message">
                        <div className='contact-box about-contact'>
                            <div className="leave-your-message">
                                <div className="section-title contact-section">
                                    <h3>Leave Your Message</h3>
                                    {/* <div className="bar"></div> */}
                                </div>

                                <p>
                                    At Bling n Bliss, we’re here to assist you every step of the way.
                                    Whether you have inquiries about our venues, need help planning your event,
                                    or simply want to discuss your vision with our experienced team, we’re just a call away.
                                </p>
                                <div className="stay-connected">
                                    <div className="section-title contact-section">
                                        <h3>Stay Connected</h3>
                                        {/* <div className="bar"></div> */}
                                    </div>
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/share/TW46ey7cQ9WE4neZ/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
                                                <i className="bi bi-facebook"></i>
                                                <span>Facebook</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/@BlingnBliss" target="_blank" rel="noopener noreferrer">
                                                <i className="bi bi-youtube"></i>
                                                <span>Youtube</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/bling_n_bliss_/" target="_blank" rel="noopener noreferrer">
                                                <i className="bi bi-instagram"></i>
                                                <span>Instagram</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 contact-form-details">
                        <form id="contactForm" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6 col-md-7">
                                    <div className="form-group">
                                        <label htmlFor="contact_name">Name*</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="contact_name"
                                            id="contact_name"
                                            value={formData.contact_name}
                                            onChange={handleChange}
                                        />
                                        {errors.contact_name && <small className="form-text text-danger">{errors.contact_name}</small>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-7">
                                    <div className="form-group">
                                        <label htmlFor="contact_email">Email*</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="contact_email"
                                            id="contact_email"
                                            value={formData.contact_email}
                                            onChange={handleChange}
                                        />
                                        {errors.contact_email && <small className="form-text text-danger">{errors.contact_email}</small>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="contact_number">Phone Number*</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="contact_number"
                                            id="contact_number"
                                            value={formData.contact_number}
                                            onChange={handleChange}
                                            maxLength={10}
                                        />
                                        {errors.contact_number && <small className="form-text text-danger">{errors.contact_number}</small>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="message">Message*</label>
                                        <textarea
                                            name="message"
                                            className="form-control"
                                            id="message"
                                            cols={30}
                                            value={formData.message}
                                            onChange={handleChange}
                                            rows={4}
                                        />
                                        {errors.message && <small className="form-text text-danger">{errors.message}</small>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <button type="submit" className="btn btn-primary contact-btn">
                                        Send Message
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

// Main ContactUs Page Component
const ContactUs = () => {
    return (
        <ContactForm />
    );
};

export default ContactUs;
