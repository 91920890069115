"use client";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams,useLocation,useNavigate  } from "react-router-dom";
import { Carousel } from 'react-bootstrap';
import { ClipLoader } from "react-spinners";


const TheaterDetails = () => {
    // const { location_id } = useParams();
    const { location_id } = useParams();
    const navigate = useNavigate();

    const location = useLocation();  
    const state = location.state || {};  
    const userdate = state.selectedDate || '';  
    
  
    const [showImageModal, setShowImageModal] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);
    const [formType, setFormType] = useState('waitlist');
    const [formData, setFormData] = useState({
        name: '',
        numberOfPerson: '',
        location: '',
        whatsappNumber: '',
        selectdate: '',
        TimePrefernce: '',
        specialNote: '',
       
    });

    const [theaters, setTheaters] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const today = new Date().toISOString().split('T')[0];
    const [selectedDate1, setSelectedDate] = useState(userdate);

    
    const handleCloseImageModal = () => setShowImageModal(false);
    const handleCloseFormModal = () => 
        {
            setShowFormModal(false);
             document.body.classList.remove('modal-open'); 
        }


    const handleShowFormModal = (type) => {
        setFormType(type);
        setShowFormModal(true);
        resetForm();
        document.body.classList.add('modal-open'); 
        
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleLocationClick = (theatre_id)=>
    {
      
        
        navigate(`/book-theater/${theatre_id}`, { state: { selectedDate1 } });
    }
  
    const resetForm = () => {
        setFormData({
            name: '',
            selectdate: today,
            numberOfPerson: '',
            location: '',
            TimePrefernce: '',
            whatsappNumber: '',
            specialNote: ''
        });
    };
    const validateForm = () => {
        // Check for the 'waitlist' form type
        if (formType === 'waitlist') {
            // Validate required fields except 'TimePrefernce' and 'specialNote'
            if (Object.entries(formData).some(([key, value]) => !value && key !== 'TimePrefernce' && key !== 'specialNote')) {
                return 'All fields are required';
            }
            // Validate WhatsApp Number format
            if (!/^\d{10}$/.test(formData.whatsappNumber)) {
                return 'WhatsApp Number must be a 10-digit number';
            }
        } 
        // Check for the 'call' form type
        else if (formType === 'call') {
            // Validate all fields
            if (Object.values(formData).some(value => !value)) {
                return 'All fields are required';
            }
            // Validate WhatsApp Number format
            if (!/^\d{10}$/.test(formData.whatsappNumber)) {
                return 'WhatsApp Number must be a 10-digit number';
            }
        }
        return '';
    };
    

    const handleSubmit = async (e) => {
        const data = {
            name:formData.name,
    selectdate:formData.selectdate,
    number_of_persons:formData.numberOfPerson,
    location:formData.location,
    time_slots:formData.TimePrefernce,
    whatsapp_number:formData.whatsappNumber,
    special_note: formData.specialNote
        }

        const waitListdata = {
            name:formData.name,
    selectdate:formData.selectdate,
    number_of_persons:formData.numberOfPerson,
    location:formData.location,
    whatsapp_number:formData.whatsappNumber,
    
        }

        
        e.preventDefault();

        const errorMessage = validateForm();
        if (errorMessage) {
            toast.error(errorMessage);
            return;
        }

        try {
            if (formType === 'call') {
         
                
               const response =  await axios.post('https://api.blingnbliss.in/api/store-booking-via-call', data);
                toast.success(response.data.message);
            } 
            else if(formType==='waitlist'){
                const response =  await axios.post('https://api.blingnbliss.in/api/store-booking-waitlist', waitListdata);
                toast.success(response.data.message);
            }
            handleCloseFormModal();
        } catch (error) {
            toast.error('Error submitting form');
        }
    };

    const handleDateChange = (event) => {
      
        setSelectedDate(event.target.value);
    };

    useEffect(() => {
        if (location_id) {
            fetchLocationDetails();
        }
    }, [location_id, selectedDate1]);
    // useEffect(() => {
    //     fetchLocationDetails();
    // }, [location_id, selectedDate]);

    const fetchLocationDetails = async (date) => {
      
        
        setLoading(true);
    
        try {
            const response = await axios.post(`https://api.blingnbliss.in/api/get-theatres-by-location/${location_id}/${selectedDate1}`);
           
            console.log("response ",response.data.data.map((index)=>
            {
                console.log(index.galleryList)}));
            
            if (response.data.success) {
                if (response.data.data.length > 0) {
                    setTheaters(response.data.data);
                } else {
                    setError("No theatres available in this location.");
                }
            } else {
                setError("Failed to fetch theatre data.");
            }
        } catch (error) {
            setError("Failed to fetch data.");
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <>
            <div className="page-content">
                <div className="speakers-area-two ptb-120 theatre-container" style={{background:'#CBBBDD',paddingTop: '230px'}} >
                    <div className="container theatre-collecion">
                        <div className="row justify-content-center theatre-data">
                            <div className="col-lg-4 col-sm-8 p-0 m-2 theater-date">
                                <div className="form-group">
                                <label htmlFor="date" className="slot-text">Check Slot Availability</label>
                                    <input
                                        type="date"
                                        className="form-control slot-avaible-date"
                                        name="date"
                                        id="date"
                                        min={today}
                                        Value={userdate}
                                        onChange={handleDateChange}
                                        required
                                    />
                                </div>
                                <div className="d-flex align-items-center food-beverages">
                                <i className="bi bi-cup-straw"></i>
                                <h6 className="mb-0 ms-2">Food and Beverages can be ordered at theater</h6>
                            </div>
                            </div>
                        </div>

                        <div className="row g-4">
                            { theaters.map((theater, i) => (
                                <div className="col-lg-4 col-md-7 col-sm-10" key={i}>
                                    <div className="theater-details-contact-box">
                                    <div className="custom-card">
                                     
                                             <Carousel>
                                {theater.galleryList[0]?.image2 && JSON.parse(theater.galleryList[0].image2).map((image, index) => (
                                    <Carousel.Item key={index}>
                                        <div onClick={() => handleLocationClick(theater.theater_id)}>
                                            <img
                                                src={`https://api.blingnbliss.in/${image}`}
                                                alt={`${theater.theater_name} - ${index + 1}`}
                                                className="d-block w-100 carousel-image"
                                                // style={{ objectFit: 'cover' }}
                                            />
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                                            </div>
                                           {/* </Link> */}
                                       
                                        <div >
                                            <div onClick={() => handleLocationClick(theater.theater_id)} className="theatre-data">
                                                <h5 className="fw-bold">{theater.theater_name}</h5>
                                                <h6 className="m-2">{theater.message}</h6>
                                                <h6 className="book-notes">{theater.notes}</h6>
                                            </div>
                                        </div>
                                        <div>
                                <button type="button" className="btn btn-primary mt-3 "  onClick={() => handleLocationClick(theater.theater_id)} >
                                Book Now
                                </button>
                                </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="mt-2 text-center via-calls">
                            <button className="btn btn-primary me-2" onClick={() => handleShowFormModal('call')}>
                                Book Via Call
                            </button>
                            <button className="btn btn-primary" onClick={() => handleShowFormModal('waitlist')}>
                                Join WaitList
                            </button>
                        </div>
                    </div>
                </div>

                {/* Form Modal */}
                {showFormModal && (
                    <div className="modal-overlay">
                        <div className="modal-content1">
                            <span className="close-button" onClick={handleCloseFormModal}>
                                &times;
                            </span>
                            {formType === 'call' ? (
                                <>
                                    <h5>REQUEST CALLBACK</h5>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="name">Name *</label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="selectdate">Select Date</label>
                                            <input
                                                type="date"
                                                id="selectdate"
                                                name="selectdate"
                                                className="form-control"
                                                min={today}
                                                defaultValue={today}
                                                value={formData.selectdate}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="numberOfPerson">Number Of Person</label>
                                            <select
                                                id="numberOfPerson"
                                                name="numberOfPerson"
                                                value={formData.numberOfPerson}
                                                onChange={handleChange}
                                                className="form-control"
                                            >
                                                <option value="">Select number of persons</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i + 1} value={i + 1}>
                                                        {i + 1}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="location">Location</label>
                                            <select
                                                id="location"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleChange}
                                                className="form-control"
                                            >
                                                <option value="">Select location</option>
                                                <option>Kompally</option>
                                                <option>LB Nagar</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="TimePrefernce">Time Preference</label>
                                            <select
                                                id="TimePrefernce"
                                                name="TimePrefernce"
                                                value={formData.TimePrefernce}
                                                onChange={handleChange}
                                                className="form-control"
                                            >
                                                <option value="">Select slot</option>
                                                <option>Morning(9:00AM to 12:00AM)</option>
                                                <option>Afternoon(12:00PM to 4:00PM)</option>
                                                <option>Evening(4:00PM to 8:00PM)</option>
                                                <option>Night(9:00PM to 1:00AM)</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="whatsappNumber">WhatsApp Number *</label>
                                            <input
                                                type="text"
                                                id="whatsappNumber"
                                                name="whatsappNumber"
                                                className="form-control"
                                                value={formData.whatsappNumber}
                                                onChange={handleChange}
                                                maxLength={10}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="specialNote">Special Note</label>
                                            <textarea
                                                id="specialNote"
                                                name="specialNote"
                                                className="form-control"
                                                value={formData.specialNote}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-3">
                                            Submit
                                        </button>
                                    </form>
                                </>
                            ) : (
                                <>
                                    <h5>JOIN WAITLIST</h5>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="name">Name *</label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control"
                                                placeholder="Enter your name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="numberOfPerson">Number Of Person</label>
                                            <select
                                                id="numberOfPerson"
                                                name="numberOfPerson"
                                                value={formData.numberOfPerson}
                                                onChange={handleChange}
                                                className="form-control"
                                            >
                                                <option value="">Select number of persons</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i + 1} value={i + 1}>
                                                        {i + 1}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="location">Location</label>
                                            <select
                                                id="location"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleChange}
                                                className="form-control"
                                            >
                                                <option value="">Select location</option>
                                                <option>Kompally</option>
                                                <option>LB Nagar</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="selectdate">Select Date</label>
                                            <input
                                                type="date"
                                                id="selectdate"
                                                name="selectdate"
                                                className="form-control"
                                                min={today}
                                                defaultValue={today}
                                                value={formData.selectdate}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="whatsappNumber">WhatsApp Number (10 digit Indian number)</label>
                                            <input
                                                type="text"
                                                id="whatsappNumber"
                                                name="whatsappNumber"
                                                className="form-control"
                                                placeholder="Enter your WhatsApp number"
                                                value={formData.whatsappNumber}
                                                onChange={handleChange}
                                                required
                                                maxLength={10}
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary w-45 mt-3">
                                            Submit
                                        </button>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                )}

            

                <ToastContainer />
            </div>
        </>
    );
};

export default TheaterDetails;
