"use client";
import React from 'react';
import { ToastContainer, toast } from "react-toastify";
import { Carousel, Row, Col } from 'react-bootstrap';

const Home = () => {
    const services = [
        { imgSrc: "/images/decoration1.jpeg", title: "Decoration" },
        { imgSrc: "/images/food1.jpg", title: "Food & Beverages" },
        { imgSrc: "/images/cake.jpeg", title: "Customised Cakes" },
        { imgSrc: "/images/Bouquets.jpeg", title: "Bouquets" },
        { imgSrc: "/images/photo.jpg", title: "Photography" },
        // { imgSrc: "/images/screening.jpeg", title: "Screening" },
    ];
    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    // Chunk the services array into groups of 3
    const chunkSize = window.innerWidth < 768 ? 1 : 3;
    const chunkedServices = chunkArray(services, chunkSize);
    return (
        <div>
            {/* Main Banner */}
            <div className="main-banner slideshow-banner">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="main-banner-content">
                                <h1>Celebrate <span>Your Special</span> <br /> Moments With Us !</h1>
                                <p className="home-p">
                                    Embark on a celebration like never before at Bling n’ Bliss, where artistic moments come to life. Immerse yourself in our exclusive private theatres, setting the stage for unforgettable moments with cutting-edge technology. Elevate your celebration journey with Dolby Atmos sound systems and state-of-the-art 4K video projection.
                                </p>
                                <div className="button-box">
                                    <a className="btn btn-primary home-contact-btn" href="/contact-us/">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="slideshow">
                    <li><span style={{ backgroundImage: 'url(/images/homebanner.jpeg)' }}></span></li>
                    <li><span style={{ backgroundImage: 'url(/images/homebanner1.jpeg)' }}></span></li>
                    <li><span style={{ backgroundImage: 'url(/images/homebanner2.jpeg)' }}></span></li>
                    <li><span style={{ backgroundImage: 'url(/images/homebanner3.jpeg)' }}></span></li>
                </ul>
            </div>

            {/* About Section */}
            <div className="about-area-three bg-image branches-text ">
                <div className="about-content ">
                    <div className='text-center ourbranch-text'>
                        <h2>Our Branches</h2>
                        {/* <div className="bar"></div> */}
                    </div>

                </div>
                <div className="container branches">
                    <div className="row  align-items-center type-branches">
                        <div className="col-lg-6 col-md-8 col-sm-6 ">
                            <div className="contact-box home-branches">
                                <h5 className="text-center home-text">Kompally</h5>
                                <p>
                                    GFVG+9QF, Kompally, Kaziguda, Beside St. Martins Engineering College, Hyderabad, Telangana, 500100
                                </p>
                                <div className='text-center ' >
                                    <a href="https://maps.app.goo.gl/NQKHGUsSxw34hUak9" target="_blank" className="d-inline-flex align-items-center">
                                        <i className="bi bi-geo-alt text-center"></i>
                                        <span className="ms-2">See on map</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 col-sm-6 ">
                            <div className="contact-box home-branches">

                                <h5 className="text-center home-text">LB Nagar</h5>
                                <p>
                                    H.No 3-12-73, 4th Floor, Harsha Towers, Rock Town Colony, Nagole Rd, above Union Bank of India, L. B. Nagar, Hyderabad, Telangana 500074
                                </p>
                                <div className='text-center lb-nagar'  >
                                    <a href="https://maps.app.goo.gl/r7HgFndUbfLS1oU98" target="_blank" className="d-inline-flex align-items-center">
                                        <i className="bi bi-geo-alt text-center"></i>
                                        <span className="ms-2">See on map</span>
                                    </a>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>

            </div>


            <div className="speakers-area-two ptb-120 home-services">
                <div className="container">
                    <div className="section-title our-services text-center">
                        <h2>Our Services</h2>
                    </div>

                    <Carousel >
                        {chunkedServices.map((serviceChunk, index) => (
                            <Carousel.Item key={index} className='home-carousel-item'>
                                <Row>
                                    {serviceChunk.map((serviceItem, subIndex) => (
                                        <Col lg={4} md={6} sm={12} key={subIndex}>
                                            <div className="single-speakers-box text-center">
                                                <div className="speakers-image img1 mb-3">
                                                    <img
                                                        alt={serviceItem.title}
                                                        loading="lazy"
                                                        src={serviceItem.imgSrc}
                                                        className="home-services d-block"
                                                    />
                                                </div>
                                                <h3 className="decoration-text">{serviceItem.title}</h3>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>

            {/* How to Book Section */}
            <div className="schedule-area schedule-style-three bg-image ptb-120" >
                <div style={{ background: '#CBBBDD' }} >
                    <div className="container book-container">
                        <div className="section-title how-to-book-title text-center">
                            <h2>How to <b>Book</b></h2>
                            {/* <div className="bar"></div> */}
                        </div>
                        <div className='process-of-booking-theater'>
                            <div className="funfacts-area ptb-100">
                                <div className="row justify-content-center how-to-book-row">
                                    <div className="col-lg-3 col-md-5 col-sm-8">
                                        <div className="single-funfact">
                                            <div className="icon"><i class="bi bi-tv"></i></div>
                                            <h3>Choose Theatre</h3>
                                            <p>Select the one that matches your celebration</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-5 col-sm-8">
                                        <div className="single-funfact">
                                            <div className="icon"><i className="bi bi-cake2"></i></div>
                                            <h3>Select Addons</h3>
                                            <p>Customised Cakes, Decoration, Bouquets, and etc.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-5 col-sm-8">
                                        <div className="single-funfact">
                                            <div className="icon"><i className="bi bi-credit-card"></i></div>
                                            <h3>Make Payment</h3>
                                            <p>Check Your Cart and make the payment</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-5 col-sm-8">
                                        <div className="single-funfact">
                                            <div className="icon"><i className="bi bi-emoji-sunglasses"></i></div>
                                            <h3>Celebrate</h3>
                                            <p>Come, celebrate, and make your event memorable with us</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Home;
