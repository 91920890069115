import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';

import Footer from './components/Footer';
import BackToTop from './components/BackToTop';
import Home from './pages/Home'; // Now contains all main content
import AboutUs from './pages/AboutUs';
import Addons from './pages/Addons';

import 'bootstrap-icons/font/bootstrap-icons.css';
import RefundPolicy from './pages/RefundPolicy';
import Gallery from './pages/Gallery';
import ContactUs from './pages/ContactUs';
import BookNow from './pages/BookNow';
import TheaterDetails from './pages/TheaterDetails'; 
import SelectedTheater from './pages/SelectedTheater';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions  from './pages/TermsAndConditions';
import Payment from './pages/Payment';
import ThankYou from './pages/ThankYou';

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/addons" element={<Addons />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/book-now" element={<BookNow />} />
          <Route path="/theater-list/:location_id" element={<TheaterDetails />} /> 
          <Route path="/book-theater/:theater_id" element={<SelectedTheater />} />
          <Route path="/privacy-policy" element ={<PrivacyPolicy/>}/>
          <Route path="/terms-conditions" element ={<TermsAndConditions/>}/>
          <Route path="/payment-response" element={<Payment />}/>
          <Route path="/thank-you" element={<ThankYou/>}/>
          {/* Define other routes if needed */}
        </Routes>
        <Footer />
        <BackToTop />
      </div>
    </Router>
  );
};

export default App;
