"use client";
import React, { useEffect, useState } from "react";
import axios from "axios";
import '../styles/AddonsPage.css';
const AddonsPage = () => {
    const [addons, setAddons] = useState([]);
    // const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAddons = async () => {
            try {
                const response = await axios.post("https://api.blingnbliss.in/api/get-addons-by-category");
                if (response.data.success) {
                    setAddons(response.data.data); // Access the `data` field from the response
                } else {
                    setError("Failed to fetch data.");
                }
                // setLoading(false);
            } catch (error) {
                setError("Failed to fetch data.");
                // setLoading(false);
            }
        };

        fetchAddons();
    }, []);

    const categories = addons.reduce((acc, addon) => {
        const categoryName = addon.category.category_name;
        if (!acc[categoryName]) {
            acc[categoryName] = [];
        }
        acc[categoryName].push(addon);
        return acc;
    }, {});

    return (
        <div className="page-content">
    
            <div className="add-ons-page ptb-120" style={{background:'#CBBBDD',paddingTop: '280px'}}>
                <div className="container card-row-addons">
                    {Object.entries(categories).map(([categoryName, addonsList], index) => (
                        <div key={index} className="category-section mb-5 ">
                            <div className="section-title addon-section">
                            <h2>{categoryName}</h2>
                            {/* <div className="bar"></div> */}
                           </div>
                            <div className="row ">
                                {addonsList.map((addon, i) => (
                                    <div className="col-lg-3 col-md-6 col-sm-4 mb-4" key={i}>
                                        <div className="card addon-card">
                                            <img
                                                src={`https://api.blingnbliss.in/${addon.image}`}
                                                alt={addon.addons_name}
                                                className="card-img-top"
                                            />
                                            <div className="card-body">
                                                <h5 className="card-title ">{addon.addons_name}</h5>
                                                <p className="card-text">Price: ₹{addon.price}</p>
                                                {/* Add more details if needed */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AddonsPage;
