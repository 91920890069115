
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axios  from 'axios';
import '../styles/Navbar.css'
const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [offersData, setOffersData] = useState([]);
    const toggleMenu = (e) => {
        e.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const fetchOffersData = async () => {
        try {
            const response = await axios.post("https://api.blingnbliss.in/api/get-corousel");
            // console.log("response data is ", response.data);

            // Check if data is available and properly formatted
            if (response.data.success && response.data.data && response.data.data.corousel && response.data.data.corousel.length > 0) {
                // Extract the carousel text from the response
                const carousselText = response.data.data.corousel[0].caroussel_text;
                const formattedText = carousselText.replace(/'([^']+)'/g, "<span class='highlight'>$1</span>");
                setOffersData(formattedText);
            } else {
                console.warn("No carousel data available");
                setOffersData(""); // Handle cases where there is no data
            }
        } catch (error) {
            console.error("Error fetching offers data: ", error);
        }
    };

    const handleScroll = () => {
        let elementId = document.getElementById('navbar');
        if (window.scrollY > 170) {
            elementId?.classList.add('is-sticky');
        } else {
            elementId?.classList.remove('is-sticky');
        }
    };

    useEffect(() => {
    
        document.addEventListener('scroll', handleScroll);
        fetchOffersData();

    
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const classOne = isMenuOpen
        ? 'collapse navbar-collapse show'
        : 'collapse navbar-collapse';
    const classTwo = isMenuOpen
        ? 'navbar-toggler navbar-toggler-right'
        : 'navbar-toggler navbar-toggler-right collapsed';

    return (
        <div id="navbar" className="elkevent-nav">
            <nav className="navbar navbar-expand-lg navbar-expand-md navbar-light">
                <div className="container">
                    <NavLink className="navbar-brand" to="/">
                        <img alt="logo" src="../images/blishlogo.png" width="120" height="38" />
                    </NavLink>
                    <button
                        // className={classTwo}
                        className={`navbar-toggler ${isMenuOpen ? '' : 'collapsed'}`}
                        type="button"
                        onClick={toggleMenu}
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        
                        aria-label="Toggle navigation"
                    >
                        <span className="icon-bar top-bar"></span>
                        <span className="icon-bar middle-bar"></span>
                        <span className="icon-bar bottom-bar"></span>
                    </button>
                    <div>
                        <div className='contact-text nav-address bling-address-bar'>
                            <ul className="navbar-nav ms-auto  ">
                                <li className="nav-item address-data top-address-nav"><a className="nav-link" href="https://www.google.com/maps?" target="_blank">
                                    <i className="bi bi-geo-alt address-icons" ></i>Hyderabad</a></li>
                                <li className="nav-item address-data top-address-nav"><a className="nav-link"><i className="bi bi-envelope address-icons"></i>blingnbliss1@gmail.com</a></li>
                                <li className="nav-item address-data top-address-nav"><a className="nav-link" href='https://api.whatsapp.com/send/?phone=918121697022&text&type=phone_number&app_absent=0' target="_blank"> <i className="bi bi-whatsapp address-icons"></i>+91 8121697022</a></li>
                                <li className="nav-item home-nav-media-icons">
                                    <ul className="social-links1  d-flex m-2">
                                        <li>
                                            <a
                                                href="https://www.facebook.com/share/TW46ey7cQ9WE4neZ/?mibextid=qi2Omg"
                                                className="facebook "
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="bi bi-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.youtube.com/@BlingnBliss"
                                                className="linkedin"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="bi bi-youtube"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/bling_n_bliss_/"
                                                className="instagram"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="bi bi-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
      
                        <div className={classOne} id="navbarSupportedContent ">
                            <ul className="navbar-nav ms-auto bling-nav">
                                <li className="nav-item"><NavLink className="nav-link1" to="/" onClick={() => setIsMenuOpen(false)}>Home</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link1" to="/about-us/" onClick={() => setIsMenuOpen(false)}>About Us</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link1" to="/addons/" onClick={() => setIsMenuOpen(false)}>Add Ons</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link1" to="/refund-policy/" onClick={() => setIsMenuOpen(false)}>Refund Policy</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link1" to="/gallery/" onClick={() => setIsMenuOpen(false)}>Gallery</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link1" to="/contact-us/" onClick={() => setIsMenuOpen(false)}>Contact</NavLink></li>
                            </ul>
                            <div className="others-option">
                                <ul>
                                    <li><NavLink className="btn btn-primary book-now-home" to="/book-now/" onClick={() => setIsMenuOpen(false)}>Book Now</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                
            </nav>
            <div className="scroll-container">
            <div className="scroll-text" dangerouslySetInnerHTML={{ __html: offersData }} />
        </div>
            <div>

            </div>
        </div>
    );
};

export default Navbar;

