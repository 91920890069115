import React, { useEffect, useState } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";

const Payment = () => {
    const [loading, setLoading] = useState(true); // State to manage loading
    const [paymentSessionId, setPaymentSessionId] = useState("");
    const [processing, setProcessing] = useState(false); // State to manage payment processing

    useEffect(() => {
        const fetchPaymentSessionId = async () => {
            try {
                const response = await axios.post("https://api.blingnbliss.in/api/payment-init");
                setPaymentSessionId(response.data.paymentSessionId); // Adjust based on your API response
            } catch (error) {
                console.error("Error fetching payment session:", error);
            } finally {
                setLoading(false); // Set loading to false when the request is finished
            }
        };

        fetchPaymentSessionId();
    }, []);

    const handlePaymentClick = () => {
        if (window.Cashfree && paymentSessionId) {
            setProcessing(true);
            window.Cashfree.checkout({
                paymentSessionId: paymentSessionId
            });
        } else {
            console.error("Cashfree SDK not loaded or paymentSessionId not set");
        }
    };

    return (
        <div>
            {loading ? (
                <div className="loader-container">
                    <ClipLoader
                        color="#00BFFF"
                        loading={loading}
                        size={50}
                    />
                    <p>Loading payment details...</p>
                </div>
            ) : (
                <div>
                    {processing ? (
                        <div className="processing-container">
                            <p>Payment is processing...</p>
                        </div>
                    ) : (
                        <div>
                            <button type="button" id="renderBtn" onClick={handlePaymentClick}>
                                Pay Now
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Payment;
