"use client";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from "react-spinners";

const Locations = () => {
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('waitlist');
    const today = new Date().toISOString().split('T')[0];
    const [selectedDate, setSelectedDate] = useState(today);
    const [formData, setFormData] = useState({
        name: '',
        selectdate: today,
        numberOfPerson: '',
        location: '',
        TimePrefernce: '',
        whatsappNumber: '',
        specialNote: ''
    });

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const validateForm = () => {
        if (modalType === 'waitlist') {
            if (Object.entries(formData).some(([key, value]) => !value && key !== 'TimePrefernce' && key !== 'specialNote')) {
                return 'All fields are required';
            }
            if (!/^\d{10}$/.test(formData.whatsappNumber)) {
                return 'WhatsApp Number must be a 10-digit number';
            }
        } else if (modalType === 'call') {
            if (Object.values(formData).some(value => !value)) {
                return 'All fields are required';
            }
            if (!/^\d{10}$/.test(formData.whatsappNumber)) {
                return 'WhatsApp Number must be a 10-digit number';
            }
        }
        return '';
    };

    useEffect(() => {
        const fetchLocations = async () => {
            setLoading(true);
            try {
                const response = await axios.post("https://api.blingnbliss.in/api/get-locations");
                if (response.data.data.locations) {
                    const locations = response.data.data.locations;
                    
                    // Sort locations so that "Kompally" appears first
                    const sortedLocations = locations.sort((a, b) => {
                        const nameA = a.location_name.toLowerCase();
                        const nameB = b.location_name.toLowerCase();
                        if (nameA === 'kompally') return -1;
                        if (nameB === 'kompally') return 1;
                        return 0;
                    });
    
                    setLocations(sortedLocations);
                } else {
                    setError("Failed to fetch data.");
                }
            } catch (error) {
                setError("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };

        fetchLocations();
    }, []);

    const handleShowModal = (type) => {
        setModalType(type);
        setShowModal(true);
         document.body.classList.add('modal-open');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalType(null);
        resetForm();
        document.body.classList.remove('modal-open');
    };

    const navigate = useNavigate();

    const handleLocationClick = (location_id) => {
        navigate(`/theater-list/${location_id}`, { state: { selectedDate } });
    };
    const handleBookNowClick = (location_id) => {
        navigate(`/theater-list/${location_id}`, { state: { selectedDate } });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const resetForm = () => {
        setFormData({
            name: '',
            selectdate: today,
            numberOfPerson: '',
            location: '',
            TimePrefernce: '',
            whatsappNumber: '',
            specialNote: ''
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name: formData.name,
            selectdate: formData.selectdate,
            number_of_persons: formData.numberOfPerson,
            location: formData.location,
            time_slots: formData.TimePrefernce,
            whatsapp_number: formData.whatsappNumber,
            special_note: formData.specialNote
        };

        const waitListdata = {
            name: formData.name,
            selectdate: formData.selectdate,
            number_of_persons: formData.numberOfPerson,
            location: formData.location,
            whatsapp_number: formData.whatsappNumber,
        };
        const errorMessage = validateForm();
        if (errorMessage) {
            toast.error(errorMessage);
            return;
        }

        setLoading(true);

        try {
            if (modalType === 'call') {
                const response = await axios.post('https://api.blingnbliss.in/api/store-booking-via-call', data);
                toast.success(response.data.message);
            } else if (modalType === 'waitlist') {
                const response = await axios.post('https://api.blingnbliss.in/api/store-booking-waitlist', waitListdata);
                toast.success(response.data.message);
            }
            handleCloseModal();
        } catch (error) {
            toast.error('Error submitting form');
        } finally {
            setLoading(false);
        }
    };

  
    return (
        <div className="page-content">
            <div className="locations-area ptb-120" style={{background:'#CBBBDD',paddingTop: '280px'}}>
                <div className="container book-now">
                    <div className="section-title text-center ">
                        <h2 className="book-now-locations">Locations in Hyderabad</h2>
                    </div>
                
                    <div className="row justify-content-center ">
                            <div className="col-lg-4 col-md-7 col-sm-6  m-2 theater-date">
                                <div className="form-group">
                                  <label htmlFor="date" className="slot-text">Check Slot Availability</label>
                                    <input
                                        type="date"
                                        className="form-control slot-avaible-date"
                                        name="date"
                                        id="date"
                                        min={today}
                                        Value={selectedDate}
                                        onChange={handleDateChange}
                                        required
                                    />
                                </div>
                            </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-lg-2"></div>
                        {locations.map((location) => (
                            <div className="col-lg-4 col-md-7 col-sm-8 p-4" key={location.location_id}>
                                
                                <div className="book-now-contact-box ">
                                    <div className="custom-card">
                                    <div onClick={() => handleLocationClick(location.location_id)}>
                                                        <img
                                                            src={`https://api.blingnbliss.in/${location.image}`}
                                                            alt={location.location_name}
                                                            width={800}
                                                            height={800}
                                                            className="pricingtable-img"
                                                        />
                                    </div>
                                                    
                                                        
                                    </div>
                                <div className="map-icon">
                                <a
                                href={location.google_map_link} 
                                target="_blank"
                                rel="noopener noreferrer"
                            >    
                                     <img
                                src="/images/maps.png" 
                                alt="Google Maps Location"
                                style={{ width: '44px' }}
                                
                                />
                                </a>
                                </div>
                                <h3 className="book-location">{location.location_name}</h3>
                                <div>
                                <button type="button" className="btn btn-primary mt-3 book-btn"  onClick={() => handleBookNowClick(location.location_id)} >
                                Book Now
                                </button>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className="text-center booking-theater">
                        <button className="btn btn-primary me-2" onClick={() => handleShowModal('call')}>
                            Book Via Call
                        </button>
                        <button className="btn btn-primary" onClick={() => handleShowModal('waitlist')}>
                            Join WaitList
                        </button>
                    </div>
                </div>

                {showModal && (
                    <div className="modal-overlay ">
                        <div className="modal-content1">
                            <span className="close-button" onClick={handleCloseModal}>
                                &times;
                            </span>
                            {modalType === 'call' ? (
                                <>
                                    <h5>REQUEST CALLBACK</h5>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="name">Name *</label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="selectdate">Select Date</label>
                                            <input
                                                type="date"
                                                id="selectdate"
                                                name="selectdate"
                                                className="form-control"
                                                min={today}
                                                defaultValue={today}
                                                value={formData.selectdate}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="numberOfPerson">Number Of Person</label>
                                            <select
                                                id="numberOfPerson"
                                                name="numberOfPerson"
                                                value={formData.numberOfPerson}
                                                onChange={handleChange}
                                                className="form-control"
                                            >
                                                <option value="">Select number of persons</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i + 1} value={i + 1}>
                                                        {i + 1}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="location">Location</label>
                                            <select
                                                id="location"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleChange}
                                                className="form-control"
                                            >
                                                <option value="">Select location</option>
                                                <option>Kompally</option>
                                                <option>LB Nagar</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="TimePrefernce">Time Preference</label>
                                            <select
                                                id="TimePrefernce"
                                                name="TimePrefernce"
                                                value={formData.TimePrefernce}
                                                onChange={handleChange}
                                                className="form-control"
                                            >
                                                <option value="">Select Slot</option>
                                                <option>Morning(9:00AM to 12:00AM)</option>
                                                <option>Afternoon(12:00PM to 4:00PM)</option>
                                                <option>Evening(4:00PM to 8:00PM)</option>
                                                <option>Night(9:00PM to 1:00AM)</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="whatsappNumber">WhatsApp Number (10 digit Indian number)</label>
                                            <input
                                                type="text"
                                                id="whatsappNumber"
                                                name="whatsappNumber"
                                                className="form-control"
                                                value={formData.whatsappNumber}
                                                onChange={handleChange}
                                                required
                                                maxLength={10}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="specialNote">Special Notes</label>
                                            <textarea
                                                id="specialNote"
                                                name="specialNote"
                                                className="form-control"
                                                rows={3}
                                                value={formData.specialNote}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary w-45 mt-3">
                                            Submit
                                        </button>
                                    </form>
                                </>
                            ) : modalType === 'waitlist' ? (
                                <>
                                    <h5>JOIN WAITLIST</h5>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="name">Name *</label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control"
                                                placeholder="Enter your name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="numberOfPerson">Number Of Person</label>
                                            <select
                                                id="numberOfPerson"
                                                name="numberOfPerson"
                                                value={formData.numberOfPerson}
                                                onChange={handleChange}
                                                className="form-control"
                                            >
                                                <option value="">Select number of persons </option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i + 1} value={i + 1}>
                                                        {i + 1}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="location">Location</label>
                                            <select
                                                id="location"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleChange}
                                                className="form-control"
                                            >
                                                <option value="">Select location</option>
                                                <option>Kompally</option>
                                                <option>LB Nagar</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="selectdate">Select Date</label>
                                            <input
                                                type="date"
                                                id="selectdate"
                                                name="selectdate"
                                                className="form-control"
                                                min={today}
                                                defaultValue={today}
                                                value={formData.selectdate}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="whatsappNumber">WhatsApp Number (10 digit Indian number)</label>
                                            <input
                                                type="text"
                                                id="whatsappNumber"
                                                name="whatsappNumber"
                                                className="form-control"
                                                placeholder="Enter your WhatsApp number"
                                                value={formData.whatsappNumber}
                                                onChange={handleChange}
                                                required
                                                maxLength={10}
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary w-45 mt-3">
                                            Submit
                                        </button>
                                    </form>
                                </>
                            ) : null}
                        </div>
                    </div>
                )}
                <ToastContainer />
            </div>
        </div>
    );
};

export default Locations;
